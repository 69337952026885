<template>
  <div>
    <div id="container">

      <section id="register">
        <div class="wrap">
          <form @submit="submitClicked">
            <div class="cts_box">
              <h2 class="sub_ttl"><span>複眼経済塾 ワンコイン体験入塾申込み</span></h2>
              <div class="experience">
                <div class="main_img"><img src="/common/images/experience_mv.jpg" alt="ワンコイン体験入塾へようこそ！"></div>
                <h3>ワンコイン体験入塾へようこそ！</h3>
                <p class="alnC sp_alnL">
                  以下、いくつかお手続きについて<span class="bold red">注意事項</span>があります。<span class="bold red">良くお読みください。</span>
                </p>
                <ul class="dotted bold mt20">
                  <li>ワンコイン体験入塾は、<span class="border red">お手続きの当月末まで500円（税込）で、人気講義の一部をご受講頂ける</span>制度です。</li>
                  <li>ワンコイン体験入塾は、翌月より入塾のご意志がある前提で入塾を解放するものです。このため、体験入塾期間を終えた翌月1日からは「本科」に自動入塾になる設定となっており、翌月からは月謝（16,500円・税込）が発生し、ご登録クレジットカードより自動決済されます。</li>
                  <li>もし翌月から「本科」に<span class="border red">入塾を希望しない場合</span>は、<span class="border red">必ず期日までに「入塾キャンセル」の手続き</span>を行ってください（キャンセル期日はお申込み確認メールに記載されます。必ずご確認願います）。期日までにキャンセル手続きを行わない場合は、入塾の意志があるものとして<span class="border red">自動的に入塾手続き</span>が取られます。<span class="border red">あらかじめご注意ください。</span></li>
                  <li>仮に上述の通り、入塾前に期日までにキャンセル手続きを頂いた場合は、月謝等の決済は一切行われません。</li>
                </ul>
                <div class="alnC"><img src="/common/images/experience_cancel.png" alt="キャンセルについて"></div>
              </div>

              <template v-if="false">
                <h3 class="min_ttl mt20">お支払い方法をお選びください</h3>
                <div class="selected_class">
                  <h4>現在選択中のクラス</h4>
                  <div class="class_name">{{ rank_label() }}</div>
                </div>
                <div class="reg_set first">
                  <div class="class_list_box">
                    <div class="tax_ttl flex">
                      <h4>お支払い方法を選択してください</h4>
                      <p class="tax_caution bold red">※表示はすべて税込価格です</p>
                    </div>

                    <!-- 本科プラス -->
                    <div
                        v-if="new_user.rank === 'rank_regular_plus_month' || new_user.rank === 'rank_regular_plus_year'">
                      <div class="class_list flex check_radio">
                        <input type="radio" id="rank_regular_plus_year" name="支払い方法" v-model="new_user.rank"
                               value="rank_regular_plus_year" v-on:click="rankChange()">
                        <label class="flex" for="rank_regular_plus_year">
                          <div class="class">本科プラス 年額払い</div>
                          <div class="price">¥264,000/年</div>
                          <div class="info">月あたり¥22,000</div>
                          <div class="btn"><span></span>
                            <p>選択する</p></div>
                        </label>
                      </div>
                      <div class="class_list flex check_radio">
                        <input type="radio" id="rank_regular_plus_month" name="支払い方法" v-model="new_user.rank"
                               value="rank_regular_plus_month" v-on:click="rankChange()">
                        <label class="flex" for="rank_regular_plus_month">
                          <div class="class">本科プラス 月額払い</div>
                          <div class="price">¥27,500/月</div>
                          <div class="info">&nbsp;</div>
                          <div class="btn"><span></span>
                            <p>選択する</p></div>
                        </label>
                      </div>
                    </div>

                    <!-- 本科 -->
                    <div v-if="new_user.rank === 'rank_regular_month' || new_user.rank === 'rank_regular_year'">
                      <div class="class_list flex check_radio">
                        <input type="radio" id="rank_regular_year" name="支払い方法" v-model="new_user.rank"
                               value="rank_regular_year" v-on:change="rankChange()">
                        <label class="flex" for="rank_regular_year">
                          <div class="class">本科 年額払い</div>
                          <div class="price">¥162,000/年</div>
                          <div class="info">月あたり¥13,500</div>
                          <div class="btn"><span></span>
                            <p>選択する</p></div>
                        </label>
                      </div>
                      <div class="class_list flex check_radio">
                        <input type="radio" id="rank_regular_month" name="支払い方法" v-model="new_user.rank"
                               value="rank_regular_month" v-on:change="rankChange()">
                        <label class="flex" for="rank_regular_month">
                          <div class="class">本科 月額払い</div>
                          <div class="price">¥16,500/月</div>
                          <div class="info">&nbsp;</div>
                          <div class="btn"><span></span>
                            <p>選択する</p></div>
                        </label>
                      </div>
                    </div>

                    <!-- 予科 -->
                    <div v-if="new_user.rank === 'rank_preparatory_month'">
                      <div class="class_list flex check_radio">
                        <input type="radio" id="rank_preparatory_month" name="支払い方法" v-model="new_user.rank"
                               value="rank_preparatory_month" v-on:click="rankChange()">
                        <label class="flex" for="rank_preparatory_month">
                          <div class="class">予科 月額払い</div>
                          <div class="price">¥4,950/月</div>
                          <div class="info">&nbsp;</div>
                          <div class="btn"><span></span>
                            <p>選択する</p></div>
                        </label>
                      </div>
                    </div>

                  </div>

                  <div class="class_list_box shikihou" v-bind:class="{no_option: no_option}" v-show="show_shikihou">
                    <!--本科で月額払いの場合は no_option を追加-->
                    <div class="tax_ttl flex">
                      <h4>東洋経済新報社の「会社四季報ONLINE」の優待プランを追加できます（年額払いのみ）</h4>
                      <p class="tax_caution bold red">※表示はすべて税込価格です</p>
                    </div>

                    <div class="class_list flex check_radio">
                      <input type="radio" id="course04" name="コース" v-model="new_user.shikiho" value=""
                             v-on:change="shikihoChange()">
                      <label class="flex" for="course04">
                        <div class="shikihou_option min">会社四季報ONLINE の優待プランを選択しない</div>
                        <div class="price pc">&nbsp;</div>
                        <div class="info pc">&nbsp;</div>
                        <div class="btn"><span></span></div>
                      </label>
                    </div>

                    <div class="class_list flex check_radio">
                      <input type="radio" id="course05" name="コース" v-model="new_user.shikiho"
                             value="rank_regular_shikiho_premium_year"
                             v-on:change="shikihoChange()">
                      <label class="flex" for="course05">
                        <div class="shikihou_option">四季報ONLINE プレミアムプランを追加する</div>
                        <div class="price">+¥50,000/年</div>
                        <div class="info red">通常より¥8,000お得</div>
                        <div class="btn"><span></span>
                          <p>選択する</p></div><!--no_option の時は「選択不可」に変更-->
                      </label>
                    </div>

                    <div class="class_list flex check_radio">
                      <input type="radio" id="course06" name="コース" v-model="new_user.shikiho"
                             value="rank_regular_shikiho_basic_year"
                             v-on:change="shikihoChange()">
                      <label class="flex" for="course06">
                        <div class="shikihou_option">四季報ONLINE ベーシックプランを追加する</div>
                        <div class="price">＋¥12,000/年</div>
                        <div class="info red">通常より¥500お得</div>
                        <div class="btn"><span></span>
                          <p>選択する</p></div><!--no_option の時は「選択不可」に変更-->
                      </label>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="cts_box">
              <h3 class="min_ttl">利用規約をご一読ください</h3>
              <p class="lead_txt">
                利用規約をご一読いただき、これらの条件にご同意の上「利用規約に同意します」にチェックを入れてから「塾生登録を開始する」ボタンを押してください。
              </p>
              <div class="mail_check">
                <form-error-box v-if="errors"/>
                <div class="input_set">
                  <div class="label">複眼経済塾利用規約</div>
                  <div class="term_box">
                    <div class="scrl_box" v-html="term.text"></div>
                  </div>
                </div>
                <div class="input_set">
                  <div class="checkbox alnC">
                    <input id="check01" type="checkbox" v-model="new_user.agreement" value="1"
                           v-on:change="agreementChanged()">
                    <label for="check01" class="bold">複眼経済塾 利用規約に同意します</label>
                  </div>
                </div>
              </div>
              <p class="alnC mt20 mb20"> 上記同意にチェックを入れると塾生登録のボタンが表示されます。 </p>
              <div class="button_wrap">
                <input type="submit" class="submit light" value="塾生登録を開始する"
                       v-bind:disabled="!this.new_user.agreement">
              </div>

              <div class="cts_box alnC mt40">
                <p>
                  <router-link :to="campaign_code ? {
                  name: 'UsersRegistrationPartner', params: {campaign_code: campaign_code}
                } : {
                  name: 'UsersRegistration'
                }" class="back">前のページにもどる
                  </router-link>
                </p>
              </div>
            </div>
          </form>
        </div><!--//.wrap-->
      </section>

    </div><!--//＃container-->
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    const campaign_code = this.$route.params.campaign_code
    if (!this.$store.state.user) {
      this.$store.state.user = {}
      this.$router.push(
          campaign_code ? {
            name: 'UsersRegistrationPartner', params: {campaign_code: campaign_code}
          } : {
            name: 'UsersRegistration'
          }
      )
    }
    this.$store.state.user.shikiho = ''
    if (this.$store.state.user.rank !== 'rank_regular_year' && this.$store.state.user.rank !== 'rank_regular_month') {
      this.$store.state.user.shikiho = 'none'
    }
    return {
      new_user: this.$store.state.user,
      term: {},
      errors: null,
      no_option: this.$store.state.user.rank !== 'rank_regular_year',
      show_shikihou: (this.$store.state.user.rank !== 'rank_preparatory_month' && this.$store.state.user.rank !== 'rank_regular_plus_year' && this.$store.state.user.rank !== 'rank_regular_plus_month'),
      campaign_code: campaign_code
    }
  },
  mounted() {
    if (!this.campaign_code) {
      if (!this.isRegistrationEnabled() && (!this.isRegistrationDebugEnabled() ||
          (this.$route.query.debug !== 'millioneyes' && this.$route.query.special_registration !== 'millioneyes'))) {
        // LPへ
        this.$router.push({name: 'lp'})
        return
      }
    }
    this.axios
        .get(`${this.env.api_base_url}term.json`)
        .then(response => {
          this.term = response.data.term
          this.new_user.term_id = this.term.id
        })
        .catch(error => {
          if (error.response.data && error.response.data.errors) {
            console.log(error.response.data);
          }
        });
  },
  created() {
    //
  },
  methods: {
    agreementChanged() {
      this.$forceUpdate();
    },
    rankChange() {
      setTimeout(() => {
        if (this.new_user.rank !== 'rank_regular_year') {
          this.new_user.shikiho = ''
          if (this.new_user.rank === 'rank_regular_year' || this.new_user.rank === 'rank_regular_month') {
            setTimeout(() => {
              window.$('#course04').prop('checked', true)
            }, 10)
          } else {
            setTimeout(() => {
              window.$('#course04').prop('checked', false)
            }, 10)
          }
        }
        this.no_option = this.new_user.rank !== 'rank_regular_year'
        this.show_shikihou = (this.new_user.rank !== 'rank_preparatory_month' && this.new_user.rank !== 'rank_regular_plus_year' && this.new_user.rank !== 'rank_regular_plus_month')
        // this.$forceUpdate();
      }, 100)
    },

    // オプション変更
    shikihoChange() {
      //console.log(this.new_user.shikiho);
    },

    // ランク名
    rank_label() {
      const labels = {
        rank_regular_plus_month: '本科プラス',
        rank_regular_plus_year: '本科プラス',
        rank_regular_month: '本科',
        rank_regular_year: '本科',
        rank_regular_shikiho_premium_year: '本科',
        rank_regular_shikiho_basic_year: '本科',
        rank_preparatory_month: '予科',
        rank_preparatory_year: '予科'
      }
      return labels[this.new_user.rank]
    },

    // 本科か
    rank_regular() {
      return this.new_user.rank === 'rank_regular_month' || this.new_user.rank === 'rank_regular_year'
    },
    submitClicked(event) {
      event.preventDefault()
      this.errors = null
      if (!this.new_user.agreement) {
        this.errors = ['登録には利用規約に同意頂く必要があります。']
      } else {
        if (this.campaign_code) {
          // console.log(this.campaign_code)
          this.new_user.campaign_code = this.campaign_code
        }
        // 遷移
        this.$router.push({name: 'UsersConfirmationPage1'})
      }
    }
  }
}
</script>
